import { extendObservable } from "mobx";

class DeviceStore {
  constructor() {
    extendObservable(this, {
      device: null
    });
  }
}

export default new DeviceStore();
