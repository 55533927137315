import { extendObservable, action } from "mobx";
import i_ from "i18next";

import { post, get, put, del, jsonParser } from "../utils/Client";

import { SIZE } from "../utils/Constants";

export default class DefaultStore {
  constructor(entity, sortColumn = "nome") {
    const url = i_.t(`${entity}.path`);
    extendObservable(this, {
      queryField: "",

      sort: { column: sortColumn, ascending: true },

      size: SIZE,

      page: 0,

      count: 0,

      list: [],

      selected: [],

      edit: null,

      open: action((id = null) =>
        get(url + `/${id ? id : this.selected[0].id}`)
          .then(jsonParser)
          .then(data => (this.edit = data))
      ),

      new: action((init = {}) => (this.edit = init)),

      create: action(() => post(url, { body: this.edit }).then(jsonParser)),

      save: action(() =>
        put(url + `/${this.edit.id}`, { body: this.edit }).then(jsonParser)
      ),

      query: action(() =>
        get(
          url +
            `?query=${this.queryField}&size=${this.size}&page=${this.page}` +
            `&sort=${this.sort.column}` +
            `&order=${this.sort.ascending ? "ASC" : "DESC"}`
        )
          .then(jsonParser)
          .then(data => {
            this.page === 0
              ? (this.list = data)
              : this.list.push.apply(this.list, data);
            this.count = data.count;
          })
      ),

      delete: action(
        () =>
          new Promise((resolve, reject) =>
            this.selected.map(i =>
              del(url + `/${i.id}`)
                .then(res => this.selected.shift())
                .then(() => {
                  if (this.selected.length === 0) resolve();
                })
            )
          )
      )
    });
  }
}
