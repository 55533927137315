import i18next from "i18next";
import moment from "moment";

import pt_br from "./pt_br.json";

i18next.init({
  lng: "pt-BR",
  debug: true,
  resources: {
    "pt-BR": { translation: pt_br }
  },
  interpolation: {
    format: (value, format, lng) => {
      if (format === "uppercase") return value.toUpperCase();
      if (value instanceof moment) return value.format(format);
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
    scape: false
  }
});
