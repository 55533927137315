import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import i_ from "i18next";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import AuthenticationStore from "../stores/AuthenticationStore";

import theme from "./theme";

import logo from "../assets/logo.png";

const styles = theme => ({
  loginContainer: {
    [theme.breakpoints.up("md")]: {
      width: "400px",
      margin: "100px auto",
    },
    padding: "30px",
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    textAlign: "center"
  },
  loginSubmit: {
    marginTop: "10px"
  },
  loginImage: {
    marginTop: "5px"
  }
});

class Register extends React.Component {
  state = {
    name: null,
    email: null,
    password: null,
    passwordConfirm: null,
    message: "",
    loading: false
  };

  handleChange = event => {
    if (event.target.id === "name") {
      this.setState({ name: event.target.value });
    } else if (event.target.id === "email") {
      this.setState({ email: event.target.value });
    } else if (event.target.id === "password") {
      this.setState({ password: event.target.value });
    } else if (event.target.id === "passwordConfirm") {
      this.setState({ passwordConfirm: event.target.value });
    }
  };

  handleRegister = () => {
    var { name, email, password, passwordConfirm } = this.state;
    if (name && email && password && passwordConfirm) {
      if (password === passwordConfirm) {
        AuthenticationStore.register(name, email, password).then(() =>
          this.props.history.push("/")
        );
      }
    }
  };

  handleKeyPress = target => {
    if (target.charCode === 13) {
      this.handleRegister();
    }
  };

  render() {
    let { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <Paper className={classes.loginContainer}>
            <div className={classes.logo}>
              <img src={logo} alt="logo" />
            </div>
            <Typography variant="h5">{i_.t("register.title")}</Typography>
            <TextField
              id="name"
              label={i_.t("register.name")}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
              required
            />

            <TextField
              id="email"
              label={i_.t("register.email")}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
              pattern="[^ @]*@[^ @]*"
              required
            />

            <TextField
              id="password"
              label={i_.t("register.password")}
              type="password"
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
            />

            <TextField
              id="passwordConfirm"
              label={i_.t("register.password-confirm")}
              type="password"
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRegister}
              fullWidth={true}
              className={classes.loginSubmit}
            >
              {i_.t("register.submit")}
            </Button>
            <Button
              variant="raised"
              onClick={() => this.props.history.push("/login")}
              fullWidth={true}
              className={classes.loginSubmit}
            >
              {i_.t("register.back")}
            </Button>
          </Paper>
          <Snackbar
            open={this.state.message !== ""}
            message={this.state.message}
            autoHideDuration={4000}
            onClose={() => this.setState({ message: "" })}
          />
        </CssBaseline>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(observer(Register)));
