import { extendObservable, action } from "mobx";
import i_ from "i18next";

import { get, form, post, jsonParser, baseUrl } from "../utils/Client";
import { history } from "../";

class AuthenticationStore {
  constructor() {
    extendObservable(this, {
      isAuthenticated: false,

      clientId: "mystra-client",

      redirectURI: null,

      responseType: null,

      state: null,

      me: {},

      schemas: [],

      testAuthenticated: action(() =>
        get("/me")
          .then(res => {
            if (res.status === 401 || res.status === 403) {
              this.isAuthenticated = false;
              history.push("/login");
              return Promise.reject();
            } else {
              this.isAuthenticated = true;
              return res;
            }
          })
          .then(jsonParser)
          .then(data => (this.me = data))
      ),

      login: action((username, password) =>
        form("/authenticate", {
          body: { username, password }
        })
          .then(res => {
            if (res.status === 403 || res.status === 401) {
              this.isAuthenticated = false;
              localStorage.apiToken = null;
              this.user = {};
              return Promise.reject(i_.t("errors.bad-credentials"));
            } else {
              this.isAuthenticated = true;
              return res.json();
            }
          })
          .then(data => {
            localStorage.apiToken = data.access_token;
            if (this.redirectURI) {
              window.location.href = `${baseUrl}/oauth2/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectURI}&state=${this.state}&response_type=code&access_token=${data.access_token}`;
            }
          })
      ),

      register: action((name, email, password) =>
        post("/register", {
          body: { name, email, password },
          credentials: "omit"
        }).then(res => this.login(email, password))
      ),

      logout: action(
        () =>
          new Promise((resolve, reject) => {
            this.isAuthenticated = false;
            this.user = {};
            localStorage.clear();
            resolve();
          })
      ),
      hasRole: action(
        role =>
          this.me.perfil &&
          this.me.perfil.autorizacoes.filter(a => a.nome === role).length > 0
      )
    });
  }
}

export default new AuthenticationStore();
