import { observer } from "mobx-react";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";

import HubEdit from "../hub/Edit";
import Stores from "../../stores/Stores";
import { Column, Row } from "../commons/Containers";
import UIStore from "../../stores/UIStore";

const store = Stores.load("hub");

const styles = {
  card: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pullRight: {
    marginLeft: "auto",
  },
};

class Home extends React.Component {
  componentDidMount() {
    if (process.env.NODE_ENV !== "production") {
      store.searchAvailables().then(() => store.query());
    } else {
      this.interval = setInterval(
        () => store.searchAvailables().then(() => store.query()),
        1000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  Hub = ({ device, classes }) => (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Hub
        </Typography>
        <Typography variant="h5" component="h2">
          {device.name
            ? device.name
            : device.code.substr(0, device.code.indexOf("-"))}
        </Typography>
      </CardContent>
      <CardActions>
        {device.devices.map((d) => (
          <Fab
            key={d.id}
            size="small"
            color={d.state === "on" ? "primary" : "default"}
            onClick={() => store.execute(d, d.state === "on" ? "off" : "on")}
          >
            {d.type === "LIGHT" ? d.ports + 1 : d.ports + 11}
          </Fab>
        ))}
      </CardActions>
      <CardActions>
        <Button
          color="secondary"
          className={classes.pullRight}
          onClick={() =>
            UIStore.showConfirmDialog(
              "Deseja desvincular este Hub, suas configurações serão perdidas.",
              "Desvincular..."
            ).then((b) => {
              if (b) {
                store.forget(device.id);
              }
            })
          }
        >
          Desvincular
        </Button>
        <Button
          className={classes.pullRight}
          onClick={() => store.open(device.id)}
        >
          {device.user ? "Editar" : "Adicionar"}
        </Button>
      </CardActions>
    </Card>
  );

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Column>
          {store.availables && store.availables.length > 0 && (
            <React.Fragment>
              <Typography variant="h3">Novos</Typography>
              <Row>
                {store.availables.map((h) => (
                  <this.Hub device={h} classes={classes} />
                ))}
              </Row>
            </React.Fragment>
          )}
          {store.list && store.list.length > 0 && (
            <React.Fragment>
              <Typography variant="h3">Dispositivos</Typography>
              <Row>
                {store.list.map((h) => (
                  <this.Hub key={h.id} device={h} classes={classes} />
                ))}
              </Row>
            </React.Fragment>
          )}
        </Column>
        <HubEdit onSave={() => store.searchAvailables()} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(observer(Home));
