import { extendObservable, action } from "mobx";
import i_ from "i18next";

class UIStore {
  constructor() {
    extendObservable(this, {
      title: null,
      queryStore: null,
      filter: false,

      asideOpened: localStorage.asideOpened === "true",
      acessoOpened: false,
      filterOpened: false,

      snackMessage: "",
      snackOpened: false,
      erro: "",

      confirmTitle: "",
      confirmMessage: null,
      confirmPromise: null,

      teste: { nome: "" },

      testeSelect: "",
      list: [
        { value: "vermelho", label: "Vermelho" },
        { value: "azul", label: "Azul" },
        { value: "roxo", label: "Roxo" }
      ],

      asideOpen: action(() => {
        this.asideOpened = !this.asideOpened;
        localStorage.asideOpened = this.asideOpened ? "true" : "false";
        if (this.asideOpened && this.acessoOpened) this.acessoOpen();
      }),

      acessoOpen: action(() => {
        this.acessoOpened = !this.acessoOpened;
        if (this.acessoOpened && this.asideOpened) this.asideOpen();
      }),

      filterOpen: action(() => (this.filterOpened = !this.filterOpened)),

      init: action(
        (title = null, queryStore = null, entity = null, filter = false) => {
          this.title = title;
          this.queryStore = queryStore;
          this.filter = filter;
          if (
            this.queryStore !== null &&
            (this.queryStore.page === undefined || this.queryStore.page === 0)
          ) {
            this.queryStore.query();
          }
        }
      ),

      showSnack: action(message => {
        this.snackMessage = message;
        if (this.snackMessage.length > 0) {
          this.snackOpened = true;
        }
      }),

      showConfirmDialog: action((message, title = "Confirme") => {
        this.confirmTitle = title;
        this.confirmMessage = message;

        var defer = () => {
          var res, rej;
          var promise = new Promise((resolve, reject) => {
            res = resolve;
            rej = reject;
          });
          promise.resolve = res;
          promise.reject = rej;
          return promise;
        };
        this.confirmPromise = defer();

        return this.confirmPromise;
      }),

      hideConfirmDialog: action(res => {
        this.confirmMessage = null;
        this.confirmPromise.resolve(res);
      })
    });
  }

  saveError = err => {
    this.showSnack(i_.t("save-error"));
  };

  redo = store =>
    store
      .query()
      .then(() => (store.edit = null))
      .then(() => (store.selected = []));
}

export default new UIStore();
