import { action } from "mobx";

import { del, get, jsonParser } from "../utils/Client";

export const create = (store) => {
  return {
    availables: [],

    searchAvailables: action(() =>
      get("/hubs/availables")
        .then(jsonParser)
        .then((data) => (store.availables = data))
    ),

    addVirtual: action((index = -1) => {
      let port = store.edit.virtualDevices.length;
      let digit = "" + (port + 1);
      store.edit.virtualDevices.push({
        ports: port,
        name: "",
        type: "SWITCH",
        properties: [
          { index: 0, name: "on", value: "1@" + digit.padStart(2, "0") },
          { index: 1, name: "off", value: "1@" + digit.padStart(2, "0") },
        ],
      });
    }),

    removeVirtual: action((item) => {
      store.edit.virtualDevices.remove(item);
      store.edit.virtualDevices.forEach((vd, i) => (vd.ports = i));
    }),

    forget: action((id) => del(`/hubs/${id}`).then(() => store.query())),

    execute: action((device, message) =>
      get(`/devices/execute/${device.id}/${message}`).then(
        () => (device.state = message)
      )
    ),
  };
};
