import { observer } from "mobx-react";
import React from "react";
import TextField from "@material-ui/core/TextField";

import EditDialog from "../commons/EditDialog";
import { Row, Column } from "../commons/Containers";

import DeviceStore from "../../stores/DeviceStore";

const translation = {
  seconds: "Segundos",
  on: "Ao ligar",
  off: "Ao desligar"
};

class ProperyEdit extends React.Component {
  render() {
    let { device } = DeviceStore;
    return (
      <EditDialog
        title={`Propriedades de ${device ? device.name : ""}`}
        opened={device !== null}
        onCancel={() => (DeviceStore.device = null)}
        onSave={() => (DeviceStore.device = null)}
      >
        <Column>
          {device &&
            device.properties.map(p => (
              <Row>
                <TextField
                  label={translation[p.name]}
                  value={p.value}
                  onChange={e => (p.value = e.target.value)}
                />
              </Row>
            ))}
        </Column>
      </EditDialog>
    );
  }
}

export default observer(ProperyEdit);
