import React from "react";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import i_ from "i18next";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  titleStyle: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  bodyStyle: {
    padding: 0,
    overflowY: "auto",
    height: "450px",
    maxHeight: "450px",
  },
  contentStyle: {
    width: "80%",
    maxWidth: "1000px",
    paddingTop: 0,
  },
  actionLeft: {
    marginRight: "auto",
  },
  inputStyle: {
    display: "none",
    width: "100%",
  },
});

class EditDialog extends React.Component {
  handleUpload = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.props.onAttached(reader.result);
      var data = new FormData();
      data.append("file", file);
      fetch(process.env.REACT_APP_API_URL + "/suporte/chamada/37/anexar", {
        method: "POST",
        credentials: "include",
        body: data,
      }).then((response) => console.log(response));
    };
    reader.readAsText(file);
  };

  actions = (onSave, onCancel) => {
    let actions = [];
    actions.push(
      <Button color="primary" onClick={onSave} key="save">
        {i_.t("dialogs.save")}
      </Button>
    );
    actions.push(
      <Button onClick={onCancel} key="cancel">
        {i_.t("dialogs.cancel")}
      </Button>
    );
    return actions;
  };

  render() {
    const {
      children,
      opened,
      onSave,
      onCancel,
      title,
      classes,
      tab,
    } = this.props;
    return (
      <Dialog fullWidth={true} open={opened}>
        <DialogTitle className={classes.titleStyle} disableTypography={true}>
          <Typography className={classes.titleStyle} variant="h5">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent style={tab === true ? { paddingTop: "0" } : {}}>
          {children}
        </DialogContent>
        <DialogActions className={classes.actionsContainerStyle}>
          {this.actions(onSave, onCancel)}
        </DialogActions>
      </Dialog>
    );
  }
}

EditDialog.propTypes = {
  children: PropTypes.node,
  opened: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.node.isRequired,
  historyModel: PropTypes.string,
  historyId: PropTypes.number,
};

export default withStyles(styles)(observer(EditDialog));
