import React from "react";
import { observer } from "mobx-react";
import i_ from "i18next";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

class TextFieldCustom extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    item: PropTypes.object.isRequired,
    entity: PropTypes.string,
    field: PropTypes.string.isRequired,
    valueType: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    label: PropTypes.string,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    onBlur: PropTypes.func
  };

  static defaultProps = {
    type: "text",
    valueType: "text",
    fullWidth: false,
    disabled: false,
    errorText: "",
    floatingLabelText: "",
    rows: 1
  };

  text = e => (this.props.item[this.props.field] = e.target.value);

  document = e =>
    (this.props.item[this.props.field] = e.target.value.formatDocument());

  money = e =>
    (this.props.item[this.props.field] = e.target.value.formatMoney());

  render() {
    const {
      valueType,
      type,
      item,
      field,
      fullWidth,
      disabled,
      label,
      rows,
      rowsMax,
      entity,
      onBlur,
      style,
      InputProps
    } = this.props;
    return (
      <TextField
        type={type}
        onChange={this[valueType]}
        fullWidth={fullWidth}
        disabled={disabled}
        label={
          entity
            ? i_.t(`${entity.camelToDash()}.${field.camelToDash()}`)
            : label
        }
        rows={rows}
        multiline={rows > 1}
        rowsMax={rowsMax}
        onBlur={e => {
          if (onBlur) onBlur(e);
        }}
        style={style}
        value={item[field]}
        InputProps={InputProps}
      />
    );
  }
}

export default observer(TextFieldCustom);
