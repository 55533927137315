import { observer } from "mobx-react";
import i_ from "i18next";
import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SettingsIcon from "@material-ui/icons/Settings";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";

import TextField from "../commons/TextField";
import SelectField from "../commons/SelectField";
import EditDialog from "../commons/EditDialog";
import { Row, Column } from "../commons/Containers";

import Stores from "../../stores/Stores";
import UIStore from "../../stores/UIStore";
import DeviceStore from "../../stores/DeviceStore";
import ProperyEdit from "./PropertyEdit";

const store = Stores.load("hub");

const model = {
  name: "",
  devices: [],
  virtualDevices: []
};

class HubEdit extends React.Component {
  state = {
    tab: 0
  };

  save = () => {
    if (store.edit.id != null) {
      console.log(store.edit);
      store
        .save()
        .then(data => {
          if (this.props.onSave) {
            this.props.onSave(data);
          }
        })
        .then(() => UIStore.redo(store))
        .catch(UIStore.saveError);
    } else {
      store
        .create()
        .then(data => {
          if (this.props.onSave) {
            this.props.onSave(data);
          }
        })
        .then(() => UIStore.redo(store))
        .catch(UIStore.saveError);
    }
  };

  updateConfig = () => {
    let hub = store.edit;
    if (hub.config === "_4_SWITCHES") {
      var i = 0;
      for (; i < hub.devices.length; i++) {
        hub.devices[i].ports = i;
        hub.devices[i].type = "LIGHT";
        delete hub.devices[i].properties;
      }
      for (; i < 4; i++) {
        hub.devices.push({ ports: i, type: "LIGHT", name: "" });
      }
    } else if (hub.config === "_2_SWITCHES_1_COURTAIN") {
      if (hub.devices.length === 4) {
        hub.devices = hub.devices.slice(0, 2);
        hub.devices.push({
          ports: 23,
          type: "CURTAIN",
          name: "",
          properties: [{ name: "seconds", value: "60" }]
        });
      } else if (hub.devices.length === 2) {
        hub.devices = [
          { ports: 1, type: "LIGHT", name: "" },
          { ports: 2, type: "LIGHT", name: "" },
          hub.devices[1]
        ];
      }
    } else if (hub.config === "_2_COURTAIN") {
      if (hub.devices.length === 4) {
        hub.devices = [
          {
            ports: 1,
            type: "CURTAIN",
            name: "",
            properties: [{ name: "seconds", value: "60" }]
          },
          {
            ports: 23,
            type: "CURTAIN",
            name: "",
            properties: [{ name: "seconds", value: "60" }]
          }
        ];
      } else {
        hub.devices = [
          {
            ports: 1,
            type: "CURTAIN",
            name: "",
            properties: [{ name: "seconds", value: "60" }]
          },
          hub.devices[2]
        ];
      }
    }
  };

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { tab } = this.state;
    this.item = store.edit != null ? store.edit : model;
    return (
      <>
        <EditDialog
          title={i_.t("hub.title", {
            context: this.item.id ? "edit" : "new",
            label: this.item.nome
          })}
          opened={store.edit != null}
          onCancel={() => (store.edit = null)}
          onSave={this.save}
          tab={true}
        >
          <>
            <AppBar
              position="static"
              style={{ marginLeft: "-24px", width: "calc(100% + 48px)" }}
            >
              <Tabs value={tab} onChange={this.handleChangeTab}>
                <Tab label="Geral" />
                <Tab label="Flex35" />
              </Tabs>
            </AppBar>
            <SwipeableViews index={tab}>
              <Row>
                <Column>
                  <TextField entity="hub" field="name" item={this.item} />
                  <SelectField
                    entity="hub"
                    field="config"
                    item={this.item}
                    list={[
                      "_4_SWITCHES",
                      "_2_SWITCHES_1_COURTAIN",
                      "_2_COURTAIN"
                    ]}
                    onChange={this.updateConfig}
                  />
                </Column>
                <Column>
                  {this.item.devices.map((d, i) => (
                    <Row key={i}>
                      <Chip
                        style={{ width: "40px", marginTop: "auto" }}
                        label={d.type === "LIGHT" ? d.ports + 1 : d.ports + 11}
                        color="primary"
                      />
                      <TextField
                        entity="device"
                        field="name"
                        item={d}
                        style={{ width: "225px" }}
                        InputProps={
                          d.type === "CURTAIN"
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => (DeviceStore.device = d)}
                                    >
                                      <SettingsIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                            : {}
                        }
                      />
                    </Row>
                  ))}
                </Column>
              </Row>
              <Row>
                <Column>
                  {this.item.virtualDevices.map((vd, i) => (
                    <Row key={i}>
                      <Chip
                        style={{ width: "40px", marginTop: "auto" }}
                        label={vd.ports + 1}
                        color="primary"
                      />
                      <TextField
                        entity="device"
                        field="name"
                        item={vd}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => (DeviceStore.device = vd)}
                              >
                                <SettingsIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => store.removeVirtual(vd)}
                                color="secondary"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Row>
                  ))}
                  <Row>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={store.addVirtual}
                    >
                      Adicionar
                    </Button>
                  </Row>
                </Column>
              </Row>
            </SwipeableViews>
          </>
        </EditDialog>
        <ProperyEdit />
      </>
    );
  }
}

export default observer(HubEdit);
