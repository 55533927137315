import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import queryString from "querystring";
import i_ from "i18next";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import AuthenticationStore from "../stores/AuthenticationStore";

import logo from "../assets/logo.png";

import theme from "./theme";

const styles = theme => ({
  loginContainer: {
    [theme.breakpoints.up("md")]: {
      width: "400px",
      margin: "100px auto"
    },
    padding: "30px",
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    textAlign: "center"
  },
  loginSubmit: {
    marginTop: "10px"
  },
  loginImage: {
    marginTop: "5px"
  }
});

class Login extends React.Component {
  state = {
    user: null,
    password: null,
    passwordConfirm: null,
    message: "",
    loading: false
  };

  componentDidMount = () => {
    let params = queryString.parse(this.props.location.search);
    if (params["redirect_uri"] || params["?redirect_uri"]) {
      AuthenticationStore.redirectURI = params["redirect_uri"]
        ? params["redirect_uri"]
        : params["?redirect_uri"];
      AuthenticationStore.state = params["state"]
        ? params["state"]
        : params["?state"];
      AuthenticationStore.responseType = params["response_type"]
        ? params["response_type"]
        : params["?response_type"];
      AuthenticationStore.clientId = params["client_id"]
        ? params["client_id"]
        : params["?client_id"];
    }
  };

  handleChange = event => {
    if (event.target.id === "user") {
      this.setState({ user: event.target.value });
    } else if (event.target.id === "password") {
      this.setState({ password: event.target.value });
    } else if (event.target.id === "passwordConfirm") {
      this.setState({ passwordConfirm: event.target.value });
    }
  };

  handleLogin = () => {
    var { user, password } = this.state;
    if (user && password) {
      AuthenticationStore.login(user, password)
        .then(() => this.props.history.goBack())
        .catch(e => {
          this.setState({ message: "Usuário ou senha inválidos!" });
        });
    }
  };

  handleKeyPress = target => {
    if (target.charCode === 13) {
      this.handleLogin();
    }
  };

  render() {
    let { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <Paper className={classes.loginContainer}>
            <div className={classes.logo}>
              <img src={logo} alt="logo" />
            </div>
            <Typography variant="h5">{i_.t("login.title")}</Typography>
            {AuthenticationStore.state !== null && (
              <Typography variant="subtitle1">
                Ao efetuar login você concorda que{" "}
                {AuthenticationStore.clientId === "amazon-client" ? (
                  <strong>Amazon</strong>
                ) : (
                  <strong>Google</strong>
                )}{" "}
                possa controlar seus dispositivos.
              </Typography>
            )}
            <TextField
              id="user"
              label={i_.t("login.user")}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
            />

            <TextField
              id="password"
              label={i_.t("login.password")}
              type="password"
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              fullWidth={true}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={this.handleLogin}
              fullWidth={true}
              className={classes.loginSubmit}
            >
              {i_.t("login.login")}
            </Button>
            <Button
              variant="contained"
              onClick={() => this.props.history.push("/register")}
              fullWidth={true}
              className={classes.loginSubmit}
            >
              {i_.t("login.register")}
            </Button>
          </Paper>
          <Snackbar
            open={this.state.message !== ""}
            message={this.state.message}
            autoHideDuration={4000}
            onClose={() => this.setState({ message: "" })}
          />
        </CssBaseline>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(withStyles(styles)(observer(Login)));
