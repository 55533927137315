import React from "react";

const styles = {
  contentContainer: {
    marginTop: "64px",
    position: "relative",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms"
  },
  contentContainerBlock: {
    position: "relative",
    padding: "22px"
  }
};

class Root extends React.Component {
  render() {
    let { asideOpen } = this.props;
    let contentContainerStyle = {
      ...styles.contentContainer,
      width: asideOpen ? "calc(100% - 280px)" : "100%",
      marginLeft: asideOpen ? "280px" : "0"
    };

    return (
      <div className="content-container" style={contentContainerStyle}>
        <div
          className="content-container-block"
          style={styles.contentContainerBlock}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Root;
