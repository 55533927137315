import React from "react";
import { render } from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";

import "./langs/";

import App from "./components/App";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/home";

import "./index.css";
import "./utils/Format";

export const history = createBrowserHistory();

render(
  <Router history={history}>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <App>
        <Route path="/" component={Home} />
      </App>
    </Switch>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
