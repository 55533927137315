import { observer } from "mobx-react";
import React from "react";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import MainBar from "./MainBar";
import Root from "./Root";

import AuthenticationStore from "../stores/AuthenticationStore";

import theme from "./theme";

import "./App.css";

class App extends React.Component {
  state = { go: false };

  componentWillMount() {
    AuthenticationStore.testAuthenticated().then(() =>
      this.setState({ go: AuthenticationStore.isAuthenticated })
    );
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline>
          <MainBar />
          {this.state.go ? <Root>{this.props.children}</Root> : ""}
        </CssBaseline>
      </MuiThemeProvider>
    );
  }
}

export default observer(App);
