import { createMuiTheme } from "@material-ui/core/styles";
import { blueGrey, red } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: blueGrey,
    secondary: red,
    background: {
      default: "#d5d5d5"
    }
  }
});

export default theme;
