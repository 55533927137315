import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  paperStyle: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    },
    padding: "20px",
    justifyContent: "space-around"
  },
  columnStyle: {
    display: "flex",
    flexDirection: "column"
  },
  marginStyle: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "60px",
    marginRight: "60px"
  },
  contentContainerBlock: {
    position: "relative",
    padding: "22px",
    display: "flex",
    justifyContent: "center"
  }
});

export const Row = withStyles(styles)(({ children, classes }) => (
  <div className={classes.paperStyle}>{children}</div>
));

export const Column = withStyles(styles)(
  ({ styles = {}, children, classes }) => (
    <div className={classes.columnStyle} styles={styles}>
      {children}
    </div>
  )
);

export const Margin = withStyles(styles)(({ children, classes }) => (
  <div className={classes.marginStyle}>{children}</div>
));

export const Content = withStyles(styles)(({ children, classes }) => (
  <div className="content-container-block">{children}</div>
));
