import React from "react";
import { observer } from "mobx-react";
import i_ from "i18next";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class SelectFieldCustom extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    entity: PropTypes.string,
    labelFunc: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    list: [],
    disabled: false,
    errorText: "",
    label: "",
    multiple: false
  };

  handleChange = (event, index) => {
    let { item, field, onChange, list, multiple } = this.props;
    let values = event.target.value;
    item[field] = multiple
      ? list.filter(
          i => (i.id ? values.indexOf(i.id) >= 0 : values.indexOf(i) >= 0)
        )
      : list.find(i => (i.id ? i.id === values : i === values));
    if (onChange) {
      onChange(event, index, values);
    }
  };

  getMenuItems = () => {
    const { list, item, entity, field, multiple, labelFunc } = this.props;
    return [
      <MenuItem key={-1} value="" />,
      ...list.map((m, i) => (
        <MenuItem
          key={i}
          checked={
            multiple && item[field]
              ? item[field].indexOf(m.id ? m.id : m.value) > -1
              : false
          }
          value={m.value ? m.value : m.id ? m.id : m}
        >
          {labelFunc
            ? labelFunc(m)
            : m.label
              ? m.label
              : i_.t(`${entity.camelToDash()}.${field.camelToDash()}-label`, {
                  context: m.value ? m.value : m
                })}
        </MenuItem>
      ))
    ];
  };

  render() {
    const {
      item,
      entity,
      field,
      disabled,
      label,
      multiple,
      style
    } = this.props;
    let value = null;
    if (multiple) {
      value = item[field] ? item[field].map(i => (i.id ? i.id : i)) : [];
    } else {
      value = item[field] && item[field].id ? item[field].id : item[field];
    }
    if (value === null || value === undefined) {
      value = "";
    }
    return (
      <FormControl style={style}>
        <InputLabel>
          {entity
            ? i_.t(`${entity.camelToDash()}.${field.camelToDash()}`)
            : label}
        </InputLabel>
        <Select
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          multiple={multiple}
          style={{ minWidth: "200px" }}
        >
          {this.getMenuItems()}
        </Select>
      </FormControl>
    );
  }
}

export default observer(SelectFieldCustom);
