import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";

import UIStore from "../stores/UIStore";
import AuthenticationStore from "../stores/AuthenticationStore";

import ConfirmDialog from "./commons/ConfirmDialog";

import logo from "../assets/logo.png";

const styles = theme => ({
  mainBarStyle: {
    display: "flex",
    flexDirection: "row"
  },
  grow: {
    flexGrow: 1
  }
});

class MainBar extends React.Component {
  state = { anchorEl: null };

  handleToggle = () => UIStore.asideOpen();

  handleToggleFilter = () => UIStore.filterOpen();

  handleSair = () =>
    AuthenticationStore.logout().then(() => this.props.history.push("/login"));

  render() {
    let { classes } = this.props;

    return (
      <React.Fragment>
        <header>
          <AppBar className={classes.mainBarStyle}>
            <Toolbar className={classes.grow}>
              <div className={classes.grow}>
                <Link to="/" className="app-title">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div>
                <Button color="inherit" onClick={this.handleSair}>
                  Sair
                </Button>
              </div>
            </Toolbar>
          </AppBar>
        </header>

        <Snackbar
          autoHideDuration={4000}
          open={UIStore.snackOpened}
          message={UIStore.snackMessage}
          onRequestClose={() => (UIStore.snackOpened = false)}
        />
        <ConfirmDialog />
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(observer(MainBar)));
